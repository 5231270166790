import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import Wave from '../components/wave';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact Me`}</h1>
    <p>{`Contact me by ...`}</p>
    <h2>{`OR you can wave`}</h2>
    <p>{`Click to wave`}</p>
    <Wave mdxType="Wave" />
    <div style={{
      background: 'red'
    }}>
## Check this out
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      